import React, {useState} from 'react'

import { ShipGroupStylesState } from "../factory-ship-group/adminFactoryShipGroupHelper";
import { AdminFactoryService} from "../../../api/services/adminFactoryService";
import { showMessageModal } from "../../../actions/messageModalActions";


import { StylesTable } from './StylesTable'

export const Styles = ({shipGroup}: {shipGroup:any}) => {
    const shipGroupStylesState : ShipGroupStylesState ={
        results: [],
        error: "",
        message: "",
        page: 0,
        size: 10,
        count: 0,
        keyword: '',
        factory:  {id: 0, name: '', code: '', updatedAt: '',priority:'', fcr_comm_cd: 'EDI', fcr_supplier_id: null, fhs_fcr_cd: '' },
        factoryId: 0,
        sort: [{
          id: '',
          desc: true
        }]
      }
    

    const [loading, setLoading] = useState(false);
    const [shipGroupStyles, setShipGroupStyles] = useState(shipGroupStylesState);
    const [showAssignedStyles,setShowAssignedStyles] = useState(true);

  const getShipGroupStyles = async (page: number, size: number, sort: any,filter :string,checkedAssignStyle: boolean) => {
    let response : any = [];
    try {
      if (checkedAssignStyle) {
        let sortIdMap : any = {
          product_style_cd :'prod_styl_cd',
          priority: 'priority'
        }
        response = await AdminFactoryService.getAssignedShipGroupStyleList(
          shipGroup.team_shp_grp_id,
          `page=${(page + 1)}&pageSize=${size}&sortColumn=${sortIdMap[sort.id] ?sortIdMap[sort.id] :''}&sortDesc=${sort.desc ? 'DESC': 'ASC'}&filter=${filter}`
        );
      } else {
        /* thia map added for solar filed is different so */
        let sortIdMap : any = {
          product_style_cd :'styleNumber',
          displayName:'displayName',
          sport_desc:'sport_desc'
        }
        response = await AdminFactoryService.getShipGroupStyleList(
          shipGroup.team_shp_grp_id,
          `page=${(page + 1)}&pageSize=${size}&sortColumn=${sortIdMap[sort.id] ?sortIdMap[sort.id] :''}&sortDesc=${sort.desc ? 'DESC': 'ASC'}&filter=${filter}`
        );
      } 
      if (response.error) {
        throw response;
      }
      setLoading(false);
      setShipGroupStyles(prevState => ({
        ...prevState, 
        results: response.shipGroupStyles.map((item : any ) => {item.update = false; return item}),
        page: page,
        count: response.pages.totalCount,
        size: size,
      }))
    } catch (err: any) {
      setLoading(false);
      showMessageModal({ header: "STYLE LOAD ERROR", message: err.message, required: true });
    }
  }
 
   const handleShipGroupStylePagination = (size: number, page: number) => {
     setShipGroupStyles(prevState => ({
       ...prevState,
       page: page,
       size: size
     })) 
     if(!loading) {
       setLoading(true);
       getShipGroupStyles(page, size, shipGroupStyles.sort[0],shipGroupStyles.keyword,showAssignedStyles);
     }
   }
 
   const handleShipGroupStyleSort = (item: any) => {
     setShipGroupStyles(prevState => ({
       ...prevState,
       sort: item
     }))
     getShipGroupStyles(shipGroupStyles.page, shipGroupStyles.size, item[0],shipGroupStyles.keyword,showAssignedStyles);
   }
 
  const handleEnableShipGroupStyle = async (rowData :any ,index : number)=> {
    // TODO : better to get association id from BE
    const results = shipGroupStyles.results;
    const reqBody = {
      prod_styl_cd:rowData.product_style_cd
    }
    try {
      let response = await (results[index]['selected'] ? 
          AdminFactoryService.deleteShipGroupStyle(shipGroup.team_shp_grp_id,rowData.product_style_cd) // Remove style 
          : AdminFactoryService.addShipGroupStyle(shipGroup.team_shp_grp_id,reqBody));                 // Add style
      if (response.error) {
        throw response;
      }
    } catch (err: any) {
      showMessageModal({ header: "STYLE EDIT ERROR", message: err.message, required: true });
    }
    await getShipGroupStyles(shipGroupStyles.page, shipGroupStyles.size, shipGroupStyles.sort[0],shipGroupStyles.keyword,showAssignedStyles);
  }

  const handleUpdateShipGroupStyle = async (rowData: any, key: string, value: any, index: number)=> {
    const reqBody = {
      [key]: value
    }
    try {
      const response = await AdminFactoryService.updateShipGroupStyle(shipGroup.team_shp_grp_id, rowData.product_style_cd, reqBody);
      if (response.error) {
        throw response;
      }
      updateStyleRow(key, value, index);
    } catch (err: any) {
      const message = err.message?.replace('priority', 'override');
      showMessageModal({ header: "STYLE EDIT ERROR", message, required: true });
    }
  }
 
  const handleShowAssignStyle = (e: any )=>{
     setShowAssignedStyles(e.target.checked);
     if (e.target.checked && shipGroupStyles.sort[0]?.id !== 'prod_style_cd') {
      setShipGroupStyles(prevState => ({
        ...prevState,
        sort: [{
          id: 'product_style_cd',
          desc: false
        }]
      }))
     }
     getShipGroupStyles(0, shipGroupStyles.size, shipGroupStyles.sort[0],shipGroupStyles.keyword,e.target.checked);
  }
 
   const handleStyleSearch = (searchText : string) =>{
     setShipGroupStyles(prevState => ({
       ...prevState,
       keyword: searchText
     }));
     getShipGroupStyles(0, shipGroupStyles.size, shipGroupStyles.sort[0],searchText,showAssignedStyles);
   }

  const updateStyleRow = (key: any, value: any, index: number) => {
    const results = shipGroupStyles.results;
    results[index][key] = value;
    results[index]['update'] = true;
    setShipGroupStyles(prevState => ({
      ...prevState,
      results
    }))
  }

    return (
      <StylesTable 
        state={shipGroupStyles}
        shipGroup={shipGroup}
        handleEnableShipGroupStyle= {handleEnableShipGroupStyle}
        handleUpdateShipGroupStyle={handleUpdateShipGroupStyle}
        handlePagination={handleShipGroupStylePagination}  
        handleSort={handleShipGroupStyleSort}
        checkdShowAssignStyle={showAssignedStyles}
        handleShowAssignStyle={handleShowAssignStyle}
        handleStyleSearch={handleStyleSearch}
       />
    )
}