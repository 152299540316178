import * as React from "react";
import ReactTable from "react-table";
import { ShipGroupStylesState } from "../factory-ship-group/adminFactoryShipGroupHelper";
import Switch from "react-switch";
import { useForm } from 'react-hook-form';
import './TableStyle.scss'
interface Props {
    state: ShipGroupStylesState,
    shipGroup: any, //TODO: need to define type properly
    checkdShowAssignStyle : boolean,
    handleEnableShipGroupStyle : (item: any, index: number) => void;
    handleUpdateShipGroupStyle: (rowData: any, key: string, value: any, index: number) => void;
    handlePagination: (size: number, page: number) => void;
    handleSort: (item: any) => void;
    handleShowAssignStyle:(e: any )=> void;
    handleStyleSearch : (item:string)=> void
}

export const StylesTable:React.FC<Props> = ({
    state,
    shipGroup,
    handleEnableShipGroupStyle,
    handleUpdateShipGroupStyle,
    handlePagination,
    handleSort,
    checkdShowAssignStyle,
    handleShowAssignStyle,
    handleStyleSearch
}) => {
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const trapSpacesForRequiredFields =  (value: any) => !!value.trim(); 
    
    const searchData = watch('search')

    React.useEffect(()=>{
        if(!searchData){
            handleStyleSearch('')
        }
    },[searchData])

    const onSubmit =(data: any) => {
        handleStyleSearch(data.search)
    }

    const searchPlaceHolder = checkdShowAssignStyle ? "Search by code" : "Search by code or name";
    const searchPattern = checkdShowAssignStyle ? /^[A-Z0-9]+$/i : /^[A-Z0-9a-z\s-.,'()]+$/i;
    const patterErrorMsg = checkdShowAssignStyle ? "Only A-Z, 0-9, a-z allow" : "Only A-Z, a-z, 0-9, period, apostrophe, space and dash allow";

    return (
        <div>
            <div className="nts-styles-header">
                <h1>{shipGroup.team_shp_grp_nm}</h1>
                <label className="grid-filter-option">
                    <input type="checkbox"
                        name="sort-by"
                        className="nike-check"
                        checked={checkdShowAssignStyle}
                        onChange={(e: any) => {
                            reset();
                            handleShowAssignStyle(e);
                        }}
                        id="show_assigned_styles" />
                    <span>Show assigned styles</span>
                </label>
                <div className="nts-tab-search-bar">
                <form className="nts-basic-form nts-profile-form nts-style" onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="nts-admin-report-search">
                    <input  type="text"
                        className={`form-input`}
                        placeholder= {searchPlaceHolder}
                        {...register("search", {
                            validate: trapSpacesForRequiredFields,
                            pattern: {
                                value: searchPattern,
                                message: "invalid search"
                                }
                            })
                        }
                    />
                  {errors.search && errors.search.type === "maxLength" && (
                    <div className="required">No longer than 30 characters</div>
                  )}
                  {(errors.search && errors.search.type === "pattern") ?
                        (<div className="required">{patterErrorMsg}</div>) : ''
                  }
                    </label>
                    <button type="submit" className="btn btn-primary nts-style-search-btn">Search</button>
                    </form>
                </div>
            </div>
            <div className="nts-tab-search-results">
                <ReactTable
                    className="nts-table nts-styles-table"
                    data={state.results}
                    noDataText={""}
                    loading={false}
                    pages={Math.ceil(state.count / state.size)}
                    page={state.page}
                    pageSize={state.size}
                    manual={true}
                    sorted={state.sort}
                    onSortedChange={(newSorted, column, shiftKey) => {
                        handleSort(newSorted)
                    }}
                    onPageChange={(page: number) => {
                        handlePagination(state.size, page);
                    }}
                    onPageSizeChange={(newPageSize: number, newPage: number) => {
                        handlePagination(newPageSize, 0);
                    }}
                    columns={[
                        {
                            Header: "GROUP ID",
                            accessor: "id",
                            show: false,
                            sortable:false,
                        },
                        {
                            Header: "Name",
                            accessor: "displayName",
                            sortable:  !checkdShowAssignStyle
                        },
                        {
                            Header: "Code",
                            accessor: "product_style_cd",
                            sortable: true,
                            headerClassName: "custom-col-width",
                            className: "custom-col-width"
                        },
                        {
                            Header: "Sport",
                            accessor:"sport_desc",
                            sortable: !checkdShowAssignStyle,
                            Cell: (rowInfo: any ) => {
                                return(
                                <div style={{ position: "relative", wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
                                 <span>{rowInfo.original.sport_desc ? rowInfo.original.sport_desc :''}</span> 
                                 &nbsp;
                                 {rowInfo.original.gender ? 
                                 <span>({ rowInfo.original.gender.toUpperCase() })</span> : ""}
                                 </div>)
                            }
                        },
                        {
                            Header: "Active",
                            accessor: "selected",
                            headerClassName: "custom-col-width",
                            className: "custom-col-width",
                            sortable : false,
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative", width: "auto" }}>
                                        <Switch onChange={(e) => handleEnableShipGroupStyle(rowInfo.original, rowInfo.index)} checkedIcon={false} uncheckedIcon={false} checked={rowInfo.original.selected} />
                                    </div>
                                );
                            },
                        },
                        ...(checkdShowAssignStyle ? [{
                            Header: "Override",
                            accessor: "priority",
                            headerClassName: "custom-col-width",
                            className: "custom-col-width",
                            sortable: true,
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative", width: "auto" }}>
                                        <input type="number"
                                            name="priority"
                                            placeholder=""
                                            min="0"
                                            value={rowInfo.original.priority || ''}
                                            onChange={e => {
                                                handleUpdateShipGroupStyle(rowInfo.original, 'priority', e.target.value, rowInfo.index);
                                            }}
                                        />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "Fill-in only",
                            accessor: "fill_in_only",
                            headerClassName: "custom-col-width",
                            className: "custom-col-width",
                            sortable: false,
                            Cell: (rowInfo: any) => {
                                return (
                                    <div style={{ position: "relative", width: "auto" }}>
                                        <Switch onChange={(e) => handleUpdateShipGroupStyle(rowInfo.original, 'fill_in_only', e ? '1' : '0', rowInfo.index)} checkedIcon={false} uncheckedIcon={false} checked={rowInfo.original.fill_in_only === '1'} />
                                    </div>
                                );
                            },
                        }] : []),
                    ]}
                    minRows={0}
                    style={{ height: "auto" }}
                />
            </div>
        </div>
    )
}
